<template>
    <div class="sidebar">
        <div class="top">
            <span class="text-logo">KALKIN</span>
            <span class="text-sublogo">OPEN WORLD MAKER</span>
        </div>
        <div class="nav">
            <div
                class="nav-item"
                v-on:click="goHome"
                :class="{ active: isActive[0] }"
            >
                <HomeIcon :color="color[isActive[0]]" />Tableau de bord
            </div>
            <div
                class="nav-item"
                v-on:click="goAdmin"
                :class="{ active: isActive[4] }"
                v-if="user.credentials >= 10"
            >
                <AdminIcon :color="color[isActive[4]]" />Administration
            </div>
            <div
                class="nav-item"
                :class="{ active: isActive[1] }"
                v-on:click="goStyle"
            >
                <SheetIcon :color="color[isActive[1]]" />Modèle
            </div>
            <div
                class="nav-item"
                :class="{ active: isActive[2] }"
                v-on:click="goPdf"
            >
                <PdfIcon :color="color[isActive[2]]" />PDF
            </div>
            <div
                class="nav-item"
                :class="{ active: isActive[3] }"
                v-on:click="logout"
            >
                <LockIcon :color="color[isActive[3]]" />Déconnexion
            </div>
        </div>
    </div>
</template>
<script>
import HomeIcon from "@/components/icons/Home.vue";
import PdfIcon from "@/components/icons/Pdf.vue";
import SheetIcon from "@/components/icons/Sheet.vue";
import LockIcon from "@/components/icons/Lock.vue";
import AdminIcon from "@/components/icons/Admin.vue";

export default {
    components: {
        HomeIcon,
        PdfIcon,
        SheetIcon,
        LockIcon,
        AdminIcon,
    },
    props: ["active"],
    data: function () {
        return {
            isActive: [true, false, false, false, false],
            color: { false: "#90aaa1", true: "#00817b" },
            user: {},
        };
    },
    beforeMount() {
        if (this.active == "home") {
            this.isActive = [true, false, false, false, false];
        } else if (this.active == "style") {
            this.isActive = [false, true, false, false, false];
        } else if (this.active == "pdf") {
            this.isActive = [false, false, true, false, false];
        } else if (this.active == "admin") {
            this.isActive = [false, false, false, false, true];
        }
        this.user = this.$store.state.user.user;
    },
    methods: {
        goHome: function () {
            this.$router.push({
                name: "Home",
            });
        },
        goStyle: function () {
            this.$router.push({
                name: "Style",
                params: { state: 0 },
            });
        },
        goPdf: function () {
            this.$router.push({
                name: "Pdf",
            });
        },
        goAdmin: function () {
            this.$router.push({
                name: "AdminUser",
            });
        },
        logout: function () {
            this.$store.dispatch("user/logout");
            this.$router.push({ name: "Login" });
        },
    },
};
</script>
<style>
.sidebar {
    background-color: #ffffff;

    display: flex;
    flex-direction: column;
    width: 290px;
}
.blue {
    background-color: blue;
}
.purple {
    background-color: purple;
}
.top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 130px;
    margin-bottom: 20px;
}
.nav {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.nav-item {
    display: flex;
    flex-direction: row;
    font-family: roboto-bold;
    font-size: 16px;
    color: #90aaa1;
    gap: 20px;
    padding-left: 20px;
    cursor: pointer;
}
.nav-item.active {
    color: #00817b;
    border-right: solid 4px #00817b;
    border-right-radius: 25px;
}
.text-logo {
    font-family: roboto-bold;
    font-size: 32px;
    color: #38b39a;
    margin-bottom: 0;
    padding-bottom: 0;
}
.text-sublogo {
    font-family: roboto-bold;
    font-size: 12px;
    color: #38b39a;
    margin-top: -10px;
    padding-top: 0;
}
</style>
