<template>
    <svg
        width="20"
        height="23"
        viewBox="0 0 20 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.3748 0.958374H2.83317C1.779 0.958374 0.916504 1.82087 0.916504 2.87504V15.3334C0.916504 15.8605 1.34775 16.2917 1.87484 16.2917C2.40192 16.2917 2.83317 15.8605 2.83317 15.3334V3.83337C2.83317 3.30629 3.26442 2.87504 3.7915 2.87504H13.3748C13.9019 2.87504 14.3332 2.44379 14.3332 1.91671C14.3332 1.38962 13.9019 0.958374 13.3748 0.958374ZM13.9403 5.35712L18.569 9.98587C18.9236 10.3405 19.1248 10.8292 19.1248 11.3371V20.125C19.1248 21.1792 18.2623 22.0417 17.2082 22.0417H6.65692C5.60275 22.0417 4.74984 21.1792 4.74984 20.125L4.75942 6.70837C4.75942 5.65421 5.61234 4.79171 6.6665 4.79171H12.5794C13.0873 4.79171 13.5761 4.99296 13.9403 5.35712ZM13.3748 11.5H17.6873L12.4165 6.22921V10.5417C12.4165 11.0688 12.8478 11.5 13.3748 11.5Z"
            :fill="color"
        />
    </svg>
</template>
<script>
export default {
    props: ["color"],
    data: function () {
        return {};
    },
};
</script>
