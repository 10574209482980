<template>
    <div class="main">
        <Sidebar active="home" />
        <div class="left-card">
            <div class="card">
                <div class="head-title">
                    <span class="text-title">Derniers modèles</span
                    ><span class="text-description"
                        >Créer un nouveau modèle de PDF ou en modifier un
                        existant</span
                    >
                </div>
                <div class="list">
                    <div class="item" v-for="style of styles" :key="style.id">
                        <div class="item-left">
                            <div>
                                <img
                                    :src="
                                        './template' + style.template + '.png'
                                    "
                                    class="item-img"
                                />
                            </div>
                            <div class="text-circle">
                                <div class="item-text">
                                    Template {{ style.template }} -
                                    {{ style.name }}
                                </div>
                                <div class="list-circle">
                                    <div
                                        v-for="data of style.data"
                                        :key="data.label"
                                    >
                                        <b-iconstack>
                                            <b-icon
                                                stacked
                                                icon="circle-fill"
                                                :style="{ color: data.color }"
                                            ></b-icon>

                                            <b-icon
                                                stacked
                                                icon="circle"
                                            ></b-icon
                                        ></b-iconstack>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img
                                src="../assets/icon/pencil.svg"
                                class="logo-color"
                                v-on:click="goStyle(2, style.id)"
                            />
                        </div>
                    </div>
                </div>

                <div class="list-footer">
                    <hr class="hr-custom" />
                    <div class="item">
                        <div class="item-left">
                            <div>
                                <img
                                    src="../assets/image/preview/template.png"
                                    class="item-img"
                                />
                            </div>
                            <div class="text-circle">
                                <div class="item-text">Nouveau modèle</div>
                            </div>
                        </div>
                        <div>
                            <b-icon-plus
                                scale="2"
                                class="logo-color"
                                v-on:click="goStyle(1)"
                            ></b-icon-plus>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-card">
            <div class="card">
                <div class="head-title">
                    <span class="text-title">Derniers PDF</span
                    ><span class="text-description">Derniers PDF modifiés</span>
                </div>
                <div class="list pdf">
                    <div class="child-item" v-for="pdf of pdfs" :key="pdf.id">
                        <div class="item-text">{{ pdf.name }}</div>
                        <div class="icon-section">
                            <img
                                src="../assets/icon/download.svg"
                                class="logo-color"
                                v-on:click="downloadFile(pdf)"
                            />
                            <img
                                src="../assets/icon/pencil.svg"
                                class="logo-color"
                                v-on:click="goEditPdf(pdf)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Sidebar from "@/components/layouts/Sidebar.vue";
import { saveAs } from "file-saver";
export default {
    components: {
        Sidebar,
    },
    data: function () {
        return {
            styles: [],
            pdfs: [],
        };
    },
    async beforeMount() {
        this.styles = await this.getLastStyle();
        this.pdfs = await this.$store.dispatch("pdf/getAll", {
            userId: this.$store.state.user.user.id,
        });
    },
    methods: {
        getLastStyle: async function () {
            let styles = await this.$store.dispatch("style/getAll", {
                userId: this.$store.state.user.user.id,
            });
            styles = styles.slice(0, 4);

            return styles;
        },
        getLastPdf: async function () {
            let pdfs = await this.$store.dispatch("pdf/getAll", {
                userId: this.$store.state.user.user.id,
            });
            pdfs = pdfs.slice(0, 10);

            return pdfs;
        },
        goStyle: function (state, styleId) {
            this.$router.push({
                name: "Style",
                params: { id: styleId, state: state },
            });
        },
        downloadFile: async function (pdf) {
            let pdfs = [];
            pdfs.push(pdf.data);
            let styleSelect = await this.$store.dispatch("style/getStyle", {
                id: pdf.styleId,
            });
            let response = await this.$store.dispatch("pdf/generate", {
                style: styleSelect,
                items: pdfs,
                id: pdf.id,
            });
            let blob = new Blob([response.data], {
                type: "application/zip",
            });
            saveAs(blob, pdf.name + ".zip");
        },
        goEditPdf: function (pdf) {
            this.$router.push({
                name: "Pdf",
                params: { pdf: pdf },
            });
        },
    },
};
</script>
<style scoped>
.main {
    display: flex;
    flex-direction: row;
    gap: 50px;
    height: 100%;
    background-color: #e5e5e5;
}
.left-card {
    flex-grow: 2;
}
.right-card {
    flex-grow: 20;
}
.head-title {
    display: flex;
    flex-direction: column;
    margin-left: 31px;
    margin-top: 23px;
}
.text-title {
    font-family: roboto-bold;
    font-size: 24px;
    color: #394949;
}
.text-description {
    font-family: roboto-light;
    font-size: 16px;
    color: #a8bbb5;
    margin-top: 16px;
}
.item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 15px rgba(182, 182, 182, 0.25);
    border-radius: 16px;
    width: 90%;
    height: 108px;
    padding-left: 16px;
    padding-right: 45px;
}
.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}
.list.pdf {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;
    padding-left: 31px;
    padding-right: 25px;
}
.child-item {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 20px rgba(10, 10, 10, 0.12);
    border-radius: 16px;
    width: 100%;
    height: 39px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;

    align-items: center;
}
.item-left {
    display: flex;
    flex-direction: row;
}
.item-img {
    width: 80px;
}
.item-text {
    font-family: roboto-medium;
    color: #00817b;
    font-size: 16px;
}
.text-circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;

    margin-left: 16px;
}
.list-circle {
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.card {
    margin-top: 50px;
    height: 85%;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.logo-color {
    color: #00817b;
    cursor: pointer;
}
.list-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 38px;
}
.hr-custom {
    border: 2px solid rgba(0, 129, 123, 0.2);
    width: 200px;
}
.icon-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 25px;
    width: 8%;
}
</style>
